import * as THREE from 'three'

import Sizes from './Utils/Sizes.js'
import Time from './Utils/Time.js'
import Resources from './Utils/Resources.js'
import Debug from './Utils/Debug.js'
import Stats from './Utils/Stats.js'

import Camera from './Camera.js'
import Renderer from './Renderer.js'
import World from './World/World.js'

import sources from './sources.js'


export default class Experience {
    
    constructor(canvas) {
        console.log("Hello from the Experience")

        // making it accessible to console (not always useful)
        window.experience = this

        // Options
        this.canvas = canvas

        // Utils
        this.sizes = new Sizes()
        this.time = new Time()
        this.debug = new Debug()
        this.stats = new Stats(true)

        // 3D stuff
        this.scene = new THREE.Scene()
        this.resources = new Resources(sources)
        this.camera = new Camera(this)
        this.renderer = new Renderer(this)
        this.world = new World(this)

        // Events
        this.sizes.on('resize', () => {
            this.resize()
        })
        
        this.time.on('time', () => {
            this.update()
        })
    }

    resize() {
        console.log('Resize event')
        this.camera.resize()
        this.renderer.resize()
    }

    update() {
        this.stats.update()
        this.camera.update()
        this.world.update()
        this.renderer.update()
    }

    destroy() {
        this.sizes.off('resize') // remove listeners to event
        this.time.off('tick')

        // Traverse the whole scene
        this.scene.traverse((child) => {
            if (child.isMesh) {
                child.geometry.dispose()
                for (const key in child.material) {
                    const value = child.material[key]
                    if (value && typeof value.dispose == 'function') {
                        value.dispose()
                    }
                }
            }
        })
        // Dispose specifics
        this.camera.controls.dispose()
        this.renderer.instance.dispose()
        if (this.debug.active) {
            this.debug.ui.destroy()
        }
    }

}