import * as THREE from 'three'


export default class Environment {
    
    constructor(experience) {

        this.experience = experience
        this.scene = this.experience.scene
        this.resources = this.experience.resources
        this.debug = this.experience.debug

        // Debug
        if (this.debug.active) {
            this.debugFolder = this.debug.ui.addFolder('Environment')
            this.debugFolder.close()
        }

        this.setAmbientLight()
        this.setSunlight()
        // this.setFog()
        // this.setEnvironmentMap()
    }

    setAmbientLight() {
        this.ambientLight = new THREE.AmbientLight('#ffffff', 0.1); // color, intensity
        this.scene.add(this.ambientLight)

        if (this.debug.active) {
            this.debugFolderAmbient = this.debugFolder.addFolder('ambient')
            this.debugFolderAmbient.add(this.ambientLight, 'intensity', 0, 10, 0.001)
            this.debugFolderAmbient.addColor(this.ambientLight, 'color')
        }

    }

    setSunlight() {
        this.sunLight = new THREE.DirectionalLight('#ffffff', 0.1) // color, intensity
        this.sunLight.castShadow = true
        this.sunLight.shadow.camera.far = 15
        this.sunLight.shadow.mapSize.set(1024, 1024)
        this.sunLight.shadow.normalBias = 0.05
        this.sunLight.position.set(3.5, 2, - 1.25)
        this.scene.add(this.sunLight)

        // Debug
        if (this.debug.active) {
            this.debugFolderSunlight = this.debugFolder.addFolder('sunlight')
            this.debugFolderSunlight.add(this.sunLight, 'intensity', 0, 10, 0.001)
            this.debugFolderSunlight.addColor(this.sunLight, 'color')
            this.debugFolderSunlight.add(this.sunLight.position, 'x', -5, 5, 0.001)
            this.debugFolderSunlight.add(this.sunLight.position, 'y', -5, 5, 0.001)
            this.debugFolderSunlight.add(this.sunLight.position, 'z', -5, 5, 0.001)
        }
    }

    setFog() {
        this.fog = new THREE.Fog('#211d20', 1, 30)
        this.scene.fog = this.fog

        if (this.debug.active) {
            this.debugFolderFog = this.debugFolder.addFolder('fog')
            // this.debugFolderFog.add(this.fog, 'density', 0, 10, 0.001)
            this.debugFolderFog.addColor(this.fog, 'color')
            this.debugFolderFog.add(this.fog, 'near', 0, 1000, 0.1)
            this.debugFolderFog.add(this.fog, 'far', 0, 1000, 0.1)
        }
    }

    setEnvironmentMap() {
        this.environmentMap = {}
        this.environmentMap.intensity = 0.1
        this.environmentMap.texture = this.resources.items.environmentMapTexture
        this.environmentMap.texture.colorSpace = THREE.SRGBColorSpace

        this.scene.environment = this.environmentMap.texture

        this.environmentMap.updateMaterials = () => {
            this.scene.traverse((child) => {
                if (child.isMesh && child.material.isMeshStandardMaterial) {
                    child.material.envMap = this.environmentMap.texture
                    child.material.envMapIntensity = this.environmentMap.intensity
                    child.material.needsUpdate = true
                }
            })
        }
        this.environmentMap.updateMaterials()

        // Debug
        if (this.debug.active) {
            this.debugFolder.add(this.environmentMap, 'intensity').min(0).max(4).step(0.001)
                .onChange(this.environmentMap.updateMaterials)
                .name('envMap intensity')
        }
    }
}