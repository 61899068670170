import * as THREE from 'three'
import { mergeVertices } from 'three/addons/utils/BufferGeometryUtils.js'
import { VertexNormalsHelper } from 'three/addons/helpers/VertexNormalsHelper.js';

import vertexShader from '../Shaders/volumetricSpotlight/vertex.glsl'
import fragmentShader from '../Shaders/volumetricSpotlight/fragment.glsl'

export default class Fixture {

    constructor(experience) {

        this.experience = experience
        this.scene = this.experience.scene
        this.resources = this.experience.resources
        this.time = this.experience.time
        this.debug = this.experience.debug

        // Debug
        if (this.debug.active) {
            this.debugFolder = this.debug.ui.addFolder('Fixture')
        }

        // Setup
        this.position = new THREE.Vector3( 0, 6, 4 )
        this.lookAt = new THREE.Vector3( 0, 0, -4 )
        this.parameters = {
            color: new THREE.Color( 1, 0, 0 ),
            angle: 0.25,
            height: 12,
            attenuation: 0.3,
            angleStrength: 8,
            smokeStrength: 0.9,
            windSpeed: 0.1,
        }

        this.setFixtureModel()
        this.setLight()
        this.setLightConeGeometry()
        this.setLightConeMaterial()
        this.setLightConeMesh()

        if (this.debug.active) {
            this.debugFolder.addColor(this.parameters, 'color').onChange(() => {
                this.light.color.copy(this.parameters.color)
                this.coneMaterial.uniforms.uColor.value.set(this.parameters.color)
            })
            this.debugFolder.add(this.parameters, 'angle', 0, 1, 0.001).onChange(() => {
                this.light.angle = this.parameters.angle
                this.setLightConeGeometry()
                this.setLightConeMesh()
            })
            this.debugFolder.add(this.parameters, 'height', 1, 20, 0.01).onChange(() => {
                this.light.distance = this.parameters.height
                this.setLightConeGeometry()
                this.setLightConeMesh()
            })
        }
    }

    setFixtureModel() {
        this.geometry = new THREE.BoxGeometry(0.5, 1, 0.5)
        // Transform geometry so it follows beam direction
        this.geometry.applyMatrix4( new THREE.Matrix4().makeTranslation(0, 0.5, 0) )
        this.geometry.applyMatrix4( new THREE.Matrix4().makeRotationX( -Math.PI / 2 ) )
        this.material = new THREE.MeshStandardMaterial()

        this.mesh = new THREE.Mesh(this.geometry, this.material)
        this.mesh.position.copy(this.position)
        this.mesh.lookAt(this.lookAt)
        this.mesh.receiveShadow = true
        this.scene.add(this.mesh)
    }

    setLight() {
        this.light = new THREE.SpotLight(this.parameters.color, 10)
        this.light.distance = this.parameters.height
        this.light.angle = this.parameters.angle
        this.light.penumbra = 0
        this.light.decay = 0
        this.light.position.copy(this.position)
        this.light.target.position.copy(this.lookAt)

        this.light.castShadow = true
        this.light.shadow.camera.far = 15
        this.light.shadow.mapSize.set(1024, 1024)
        this.light.shadow.normalBias = 0.05

        this.scene.add(this.light)
        this.scene.add(this.light.target)

        if (this.debug.active) {
            this.debugFolder.add(this.light, 'intensity', 0, 10, 0.01)
            this.debugFolder.add(this.light, 'penumbra', 0, 1, 0.001)
            this.debugFolder.add(this.light, 'decay', 0, 5, 0.001)
        }
    }

    setLightConeGeometry() {
        if (this.coneGeometry) {
            this.coneGeometry.dispose()
        }

        this.coneGeometry = new THREE.CylinderGeometry(
            0.25,                                               // radiusTop
            this.parameters.angle * this.parameters.height,     // radiusBottom: tan(angle) = radius / height
            this.parameters.height,                             // height
            32,                                                 // radialSegments
            20,                                                 // heightSegments
            true,                                               // openEnded
        )
        
        this.coneGeometry = mergeVertices(this.coneGeometry) // remove duplicate vertices
        // Set origin of cone at the top and not center of volume
        this.coneGeometry.applyMatrix4( new THREE.Matrix4().makeTranslation(0, -this.parameters.height/2, 0) )
        this.coneGeometry.applyMatrix4( new THREE.Matrix4().makeRotationX( -Math.PI / 2 ) )

    }

    setLightConeMaterial() {
        this.perlinTexture = this.resources.items.perlinNoiseTexture
        this.perlinTexture.wrapS = THREE.RepeatWrapping
        this.perlinTexture.wrapT = THREE.RepeatWrapping

        this.uniforms = {
            uTime: new THREE.Uniform(0),

            uColor: new THREE.Uniform(this.parameters.color),
            uAttenuation: new THREE.Uniform(this.parameters.attenuation),
            uAngleStrength: new THREE.Uniform(this.parameters.angleStrength),

            uPerlinTexture: new THREE.Uniform(this.perlinTexture),
            uSmokeStrength: new THREE.Uniform(this.parameters.smokeStrength),
        }

        this.coneMaterial = new THREE.ShaderMaterial({
            vertexShader: vertexShader,
            fragmentShader: fragmentShader,
            uniforms: this.uniforms,

            depthWrite: false,
            blending: THREE.AdditiveBlending,
            transparent: true,
            // side: THREE.DoubleSide,
            // wireframe: true,
        })

        if (this.debug.active) {
            this.debugFolder.add(this.parameters, 'attenuation', 0, 1, 0.001).name('uAttenuation').onChange(() => {
                this.uniforms.uAttenuation.value = this.parameters.attenuation
            })
            this.debugFolder.add(this.parameters, 'angleStrength', 0, 10, 0.01).name('uAngleStrength').onChange(() => {
                this.uniforms.uAngleStrength.value = this.parameters.angleStrength
            })
            this.debugFolder.add(this.parameters, 'smokeStrength', 0, 10, 0.01).name('uSmokeStrength').onChange(() => {
                this.uniforms.uSmokeStrength.value = this.parameters.smokeStrength
            })
            this.debugFolder.add(this.parameters, 'windSpeed', 0, 1, 0.001)
        }
    }

    setLightConeMesh() {
        if (!this.coneMesh) {
            this.coneMesh = new THREE.Mesh(this.coneGeometry, this.coneMaterial)
            this.coneMesh.position.copy(this.position)
            this.coneMesh.lookAt(this.lookAt)
            this.scene.add(this.coneMesh)
        }
        else { // Edit if already existant
            this.coneMesh.geometry = this.coneGeometry
        }
    }

    update() {
        this.uniforms.uTime.value = this.time.elapsed * this.parameters.windSpeed
    }

}