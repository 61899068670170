import * as THREE from 'three'


export default class Floor {

    constructor(experience) {

        this.experience = experience
        this.scene = this.experience.scene
        this.resources = this.experience.resources

        this.setGeometry()
        this.setMaterial()
        this.setMesh()
    }

    setGeometry() {
        this.geometry = new THREE.PlaneGeometry(10, 10, 1, 1)
    }

    setMaterial() {
        this.material = new THREE.MeshPhongMaterial({
        })
    }

    setMesh() {
        this.mesh = new THREE.Mesh(this.geometry, this.material)
        this.mesh.rotation.x = - Math.PI * 0.5
        this.mesh.receiveShadow = true
        this.scene.add(this.mesh)
    }
}