uniform float uTime;
uniform vec3 uColor;
uniform float uAttenuation;
uniform float uAngleStrength;
uniform sampler2D uPerlinTexture;
uniform float uSmokeStrength;

varying vec2 vUv;
varying vec3 vNormal;
varying vec3 vPosition;


void main() {

    // Color
    vec3 color = uColor;

    // Attenuation based on distance
    float attenuation = smoothstep(uAttenuation, 1.0, vUv.y);

    // Attenuation along the edges
    vec3 normal = vec3(vNormal.x, vNormal.y, abs(vNormal.z)); // absolute z since we want both side of the cone to impact light
    normal = normalize(normal); // Renormalise to ensure all normals are the same length
    float angleAttenuation = dot(normal, vec3(0.0, 0.0, 1.0)); // angle with direction of normal: 1: facing, 0: perpendicular
    angleAttenuation = pow(angleAttenuation, uAngleStrength);
    attenuation *= angleAttenuation;

    // Smoke
    vec2 smokeUV = vUv;
    // smokeUV.x *= 0.9;
    // smokeUV.y *= 0.9;
    smokeUV.x += uTime * 0.0002;
    smokeUV.y += uTime * 0.0003;

    float smoke = texture(uPerlinTexture, smokeUV).y;
    smoke = smoothstep(0.0, 1.0, smoke);
    smoke = pow(smoke, uSmokeStrength);
    attenuation *= smoke;

    // gl_FragColor = vec4(normal, 1.0);
    gl_FragColor = vec4(color, attenuation);

    // ThreeJS includes
    #include <tonemapping_fragment>
    #include <colorspace_fragment>
}