import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'


export default class Camera {
    
    constructor(experience) {

        this.experience = experience
        this.sizes = this.experience.sizes
        this.canvas = this.experience.canvas
        this.scene = this.experience.scene

        this.setInstance()
        this.setOrbitControls()
    }

    setInstance() {
        const aspect = this.sizes.width / this.sizes.height
        this.instance = new THREE.PerspectiveCamera(35, aspect, 0.1, 100) // fov, aspect ratio, near, far
        this.instance.position.set(15, 13, 0)
        this.scene.add(this.instance)
    }

    setOrbitControls() {
        this.controls = new OrbitControls(this.instance, this.canvas)
        this.controls.enableDamping = true
    }

    resize() {
        const aspect = this.sizes.width / this.sizes.height
        this.instance.aspect = aspect
        this.instance.updateProjectionMatrix()
    }

    update() {
        this.controls.update()
    }
}