import GUI from 'lil-gui'


export default class Debug {

    constructor() {

        // Only add the debug UI if the URL contains the '#debug' hash
        // Can be kept in production builds 
        this.active = window.location.hash === '#debug'

        if (this.active) {
            console.log('Debug UI enabled')
            this.ui = new GUI()
        }
    }
}