import * as THREE from 'three'

import Environment from './Environment.js'
import Floor from './Floor.js'
import Fixture from './Fixture.js'
import Fox from './Fox.js'

export default class World {
    
    constructor(experience) {

        this.experience = experience
        this.scene = this.experience.scene
        this.resources = this.experience.resources

        // Setup the world, once the resources have loaded
        this.resources.on('ready', () => {

            this.floor = new Floor(this.experience)
            this.fixture = new Fixture(this.experience)
            this.fox = new Fox(this.experience)
            this.environment = new Environment(this.experience)
        })
    }

    update() {
        if (this.fox) {
            this.fox.update()
        }
        if (this.fixture) {
            this.fixture.update()
        }
    }
}