import EventEmitter from './EventEmitter.js'


export default class Time extends EventEmitter {
    
    constructor() {
        super()

        // Setup
        this.start = Date.now()
        this.current = this.start
        this.elapsed = 0
        this.delta = 16 // Do not use 0, it can create bugs on first frame

        // Call on next frame
        window.requestAnimationFrame(() => {
            this.tick()
        })        
    }

    tick() {

        const currentTime = Date.now()
        this.delta = currentTime - this.current
        this.current = currentTime
        this.elapsed = this.current - this.start
        
        this.trigger('time')

        // Call itself on next frame
        window.requestAnimationFrame(() => {
            this.tick()
        })
    }

}